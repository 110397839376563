import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import LocalHospitalIcon from '@mui/icons-material/LocalHospital'
import LocalPoliceIcon from '@mui/icons-material/LocalPolice'
import SecurityIcon from '@mui/icons-material/Security'
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined'
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined'
import LocalPoliceOutlinedIcon from '@mui/icons-material/LocalPoliceOutlined'
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import { Badge, styled } from '@mui/material'

const defaultIcon = style => <CorporateFareIcon sx={style} />
const organizationIcons = [
  {
    value         : -1
    , description : 'Admin'
    , icon        : (isMobile, style) => isMobile ? <SecurityOutlinedIcon /> : <SecurityIcon sx={style} />
  }
  , {
    value         : 1
    , description : 'Police'
    , icon        : (isMobile, style) => isMobile ? <LocalPoliceOutlinedIcon /> : <LocalPoliceIcon sx={style} />
  }
  , {
    value         : 2
    , description : 'Healthcare'
    , icon        : (isMobile, style) => isMobile ? <LocalHospitalOutlinedIcon /> : <LocalHospitalIcon sx={style} />
  }
  , {
    value         : 3
    , description : 'Insurance'
    , icon        : (isMobile, style) => isMobile ? <AccountBalanceOutlinedIcon /> : <AccountBalanceIcon sx={style} />
  }
]

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    height            : '2rem'
    , borderRadius    : '1rem'
    , backgroundColor : theme.palette.grey[200]
  }
}))

export default function OrganizationIcon ({ organization, userType, isMobile, style }) {
  if (Number.isInteger(organization)) {
    return (
      <StyledBadge
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={isMobile ? <SecurityOutlinedIcon fontSize='small' /> : <SecurityIcon fontSize='small' />}
        invisible={userType !== 2}
      >
        {organizationIcons.find(o => o.value === organization)?.icon(isMobile, style) || defaultIcon(style)}
      </StyledBadge>
    )
  }
  return (
    <StyledBadge
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={isMobile ? <SecurityOutlinedIcon fontSize='small' /> : <SecurityIcon fontSize='small' />}
      invisible={userType !== 2}
    >
      {organizationIcons.find(o => o.description === organization)?.icon(isMobile, style) || defaultIcon(style)}
    </StyledBadge>
  )
}
