import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'

const useLogout = () => {
  const navigate = useNavigate()
  return useCallback(() => {
    return navigate('/', { replace: true, state: {} })
  }, [navigate])
}

export default useLogout
