import { Backdrop, CircularProgress, useTheme } from '@mui/material'

export default function FullPageProgress ({ isOpen, isMobile, isFormOpen }) {
  const theme = useTheme()
  const { drawerWidth, formDrawerWidth } = theme.mixins
  const respMarginLeft = isMobile ? 0 : isFormOpen ? `${formDrawerWidth + drawerWidth}px` : `${drawerWidth}px`

  return (
    <Backdrop
      sx={{ marginLeft: respMarginLeft, zIndex: theme.zIndex.drawer + 10 }}
      open={isOpen}
    >
      <CircularProgress size={100} />
    </Backdrop>
  )
}
