import { Alert, Slide, Snackbar } from '@mui/material'

function SlideTransition (props) {
  return <Slide {...props} direction='up' />
}

export function Snack ({ message, setMessage, error, variant = '' }) {
  return (
    <>
      <Snackbar
        open={!!message}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
        onClose={(_, reason) => {
          if (reason === 'clickaway') {
            return
          }
          setMessage('')
        }}
      >
        {variant
          ? <Alert variant={variant} severity='success' sx={{ width: '100%' }}>
            {message}
          </Alert>
          : <Alert severity='success' sx={{ width: '100%' }}>{message}</Alert>
        }
      </Snackbar>
      <Snackbar
        open={!!error}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={SlideTransition}
        onClose={(_, reason) => {
          if (reason === 'clickaway') {
            return
          }
          setMessage('')
        }}
      >
        {variant
          ? <Alert variant={variant} severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
          : <Alert severity="error" sx={{ width: '100%' }}>{error}</Alert>
        }
      </Snackbar>
    </>
  )
}
