import { memo } from 'react'
import { SEVERITIES } from '../../constants'

const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`

let pinStyle = {
  cursor   : 'pointer'
  , fill   : '#d00'
  , stroke : 'none'
}

function Pin ({ fill, size = 20 }) {
  if (Number.isInteger(fill)) {
    fill = SEVERITIES.find(e => e.value === fill).fill
  }
  pinStyle = { ...pinStyle, fill }
  console.log(pinStyle)
  return (
      <svg height={size} viewBox="0 0 24 24" style={pinStyle}>
        <path d={ICON} />
      </svg>
  )
}

export default memo(Pin)

const icons = {
  1 : 'm12 17.27 4.15 2.51c.76.46 1.69-.22 1.49-1.08l-1.1-4.72 3.67-3.18c.67-.58.31-1.68-.57-1.75l-4.83-.41-1.89-4.46c-.34-.81-1.5-.81-1.84 0L9.19 8.63l-4.83.41c-.88.07-1.24 1.17-.57 1.75l3.67 3.18-1.1 4.72c-.2.86.73 1.54 1.49 1.08z'
  , 2 : 'm20.29 8.37-1-1.73c-.28-.48-.89-.64-1.37-.37L14 8.54V4c0-.55-.45-1-1-1h-2c-.55 0-1 .45-1 1v4.54L6.07 6.27c-.48-.28-1.09-.11-1.36.36l-1 1.73c-.28.48-.12 1.1.36 1.37L8 12l-3.93 2.27c-.48.28-.64.89-.37 1.37l1 1.73c.28.48.89.64 1.37.37L10 15.46V20c0 .55.45 1 1 1h2c.55 0 1-.45 1-1v-4.54l3.93 2.27c.48.28 1.09.11 1.37-.37l1-1.73c.28-.48.11-1.09-.37-1.37L16 12l3.93-2.27c.48-.27.64-.89.36-1.36'
  // , 2 : 'M15 11H9V8c0-.55-.45-1-1-1s-1 .45-1 1v8c0 .55.45 1 1 1s1-.45 1-1v-3h6v3c0 .55.45 1 1 1s1-.45 1-1V8c0-.55-.45-1-1-1s-1 .45-1 1z'
  // , 3: 'M20 11H4c-.55 0-1 .45-1 1s.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1M4 18h10c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1M20 6H4c-.55 0-1 .45-1 1v.01c0 .55.45 1 1 1h16c.55 0 1-.45 1-1V7c0-.55-.45-1-1-1'
  , 3: 'M20 11H4c-.55 0-1 .45-1 1s.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1M4 18h10c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1M20 6H4c-.55 0-1 .45-1 1v.01c0 .55.45 1 1 1h16c.55 0 1-.45 1-1V7c0-.55-.45-1-1-1'
}

export const makePin = (document, fill = '#d00', scale = 1, className, drawMaki = false, organization = 0) => {
  // create default map marker SVG
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
  const defaultHeight = 41
  const defaultWidth = 27
  svg.setAttributeNS(null, 'height', `${defaultHeight}px`)
  svg.setAttributeNS(null, 'width', `${defaultWidth}px`)
  svg.setAttributeNS(null, 'viewBox', `0 0 ${defaultWidth} ${defaultHeight}`)
  svg.setAttributeNS(null, 'cursor', 'pointer')
  svg.setAttributeNS(null, 'display', 'block')
  svg.classList.add(className)

  const markerLarge = document.createElementNS('http://www.w3.org/2000/svg', 'g')
  markerLarge.setAttributeNS(null, 'stroke', 'none')
  markerLarge.setAttributeNS(null, 'stroke-width', '1')
  markerLarge.setAttributeNS(null, 'fill', 'none')
  markerLarge.setAttributeNS(null, 'fill-rule', 'evenodd')

  const page1 = document.createElementNS('http://www.w3.org/2000/svg', 'g')
  page1.setAttributeNS(null, 'fill-rule', 'nonzero')

  const shadow = document.createElementNS('http://www.w3.org/2000/svg', 'g')
  shadow.setAttributeNS(null, 'transform', 'translate(3.0, 29.0)')
  shadow.setAttributeNS(null, 'fill', '#000000')

  const ellipses = [
    { rx: '10.5', ry: '5.25002273' }
    , { rx: '10.5', ry: '5.25002273' }
    , { rx: '9.5', ry: '4.77275007' }
    , { rx: '8.5', ry: '4.29549936' }
    , { rx: '7.5', ry: '3.81822308' }
    , { rx: '6.5', ry: '3.34094679' }
    , { rx: '5.5', ry: '2.86367051' }
    , { rx: '4.5', ry: '2.38636864' }
  ]

  for (const data of ellipses) {
    const ellipse = document.createElementNS('http://www.w3.org/2000/svg', 'ellipse')
    ellipse.setAttributeNS(null, 'opacity', '0.04')
    ellipse.setAttributeNS(null, 'cx', '10.5')
    ellipse.setAttributeNS(null, 'cy', '5.80029008')
    ellipse.setAttributeNS(null, 'rx', data.rx)
    ellipse.setAttributeNS(null, 'ry', data.ry)
    shadow.appendChild(ellipse)
  }

  const background = document.createElementNS('http://www.w3.org/2000/svg', 'g')
  background.setAttributeNS(null, 'fill', fill)

  const bgPath = document.createElementNS('http://www.w3.org/2000/svg', 'path')
  bgPath.setAttributeNS(null, 'd', 'M27,13.5 C27,19.074644 20.250001,27.000002 14.75,34.500002 C14.016665,35.500004 12.983335,35.500004 12.25,34.500002 C6.7499993,27.000002 0,19.222562 0,13.5 C0,6.0441559 6.0441559,0 13.5,0 C20.955844,0 27,6.0441559 27,13.5 Z')

  background.appendChild(bgPath)

  const border = document.createElementNS('http://www.w3.org/2000/svg', 'g')
  border.setAttributeNS(null, 'opacity', '0.25')
  border.setAttributeNS(null, 'fill', '#000000')

  const borderPath = document.createElementNS('http://www.w3.org/2000/svg', 'path')
  borderPath.setAttributeNS(null, 'd', 'M13.5,0 C6.0441559,0 0,6.0441559 0,13.5 C0,19.222562 6.7499993,27 12.25,34.5 C13,35.522727 14.016664,35.500004 14.75,34.5 C20.250001,27 27,19.074644 27,13.5 C27,6.0441559 20.955844,0 13.5,0 Z M13.5,1 C20.415404,1 26,6.584596 26,13.5 C26,15.898657 24.495584,19.181431 22.220703,22.738281 C19.945823,26.295132 16.705119,30.142167 13.943359,33.908203 C13.743445,34.180814 13.612715,34.322738 13.5,34.441406 C13.387285,34.322738 13.256555,34.180814 13.056641,33.908203 C10.284481,30.127985 7.4148684,26.314159 5.015625,22.773438 C2.6163816,19.232715 1,15.953538 1,13.5 C1,6.584596 6.584596,1 13.5,1 Z')

  border.appendChild(borderPath)

  let maki, circleContainer
  if (drawMaki) {
    maki = document.createElementNS('http://www.w3.org/2000/svg', 'g')
    maki.setAttributeNS(null, 'transform', 'translate(6.0, 7.0)')
    maki.setAttributeNS(null, 'fill', '#FFFFFF')

    circleContainer = document.createElementNS('http://www.w3.org/2000/svg', 'g')
    circleContainer.setAttributeNS(null, 'transform', 'translate(8.0, 8.0)')

    const circle1 = document.createElementNS('http://www.w3.org/2000/svg', 'circle')
    circle1.setAttributeNS(null, 'fill', '#000000')
    circle1.setAttributeNS(null, 'opacity', '0.25')
    circle1.setAttributeNS(null, 'cx', '5.5')
    circle1.setAttributeNS(null, 'cy', '5.5')
    circle1.setAttributeNS(null, 'r', '5.4999962')

    const circle2 = document.createElementNS('http://www.w3.org/2000/svg', 'circle')
    circle2.setAttributeNS(null, 'fill', '#FFFFFF')
    circle2.setAttributeNS(null, 'cx', '5.5')
    circle2.setAttributeNS(null, 'cy', '5.5')
    circle2.setAttributeNS(null, 'r', '5.4999962')

    circleContainer.appendChild(circle1)
    circleContainer.appendChild(circle2)
  }

  page1.appendChild(shadow)
  page1.appendChild(background)
  page1.appendChild(border)

  if (drawMaki) {
    page1.appendChild(maki)
    page1.appendChild(circleContainer)
  }

  if (organization) {
    const iconContainer = document.createElementNS('http://www.w3.org/2000/svg', 'g')
    iconContainer.setAttributeNS(null, 'fill', '#FFFFFF')
    iconContainer.setAttributeNS(null, 'transform', ' scale(0.8), translate(4.75, 4.0)')

    const iconPath = document.createElementNS('http://www.w3.org/2000/svg', 'path')
    iconPath.setAttributeNS(null, 'd', icons[organization])

    iconContainer.appendChild(iconPath)

    page1.appendChild(iconContainer)
  }
  svg.appendChild(page1)

  svg.setAttributeNS(null, 'height', `${defaultHeight * scale}px`)
  svg.setAttributeNS(null, 'width', `${defaultWidth * scale}px`)

  // this._element.appendChild(svg)
  return svg

  // if no element and no offset option given apply an offset for the default marker
  // the -14 as the y value of the default marker offset was determined as follows
  //
  // the marker tip is at the center of the shadow ellipse from the default svg
  // the y value of the center of the shadow ellipse relative to the svg top left is "shadow transform translate-y (29.0) + ellipse cy (5.80029008)"
  // offset to the svg center "height (41 / 2)" gives (29.0 + 5.80029008) - (41 / 2) and rounded for an integer pixel offset gives 14
  // negative is used to move the marker up from the center so the tip is at the Marker lngLat
  // this._offset = Point.convert(options && options.offset || [0, -14])
}
