import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import {
  Alert
  , Box
  , Divider
  , Toolbar
  , Typography
  , useTheme
} from '@mui/material/'
import useHttp from '../hooks/useHttp'
import useLogout from '../hooks/useLogout'
import MobileTopNav from '../components/mobile/MobileTopNav'
import MobileBottomNav from '../components/mobile/MobileBottomNav'
import DesktopNav from '../components/desktop/DesktopNav'
import UserForm from '../components/forms/UserForm'
import FullPageProgress from '../components/FullPageProgress'

/*
Fields editable by user:
- name
- surname
- department
- password
*/

// eslint-disable-next-line no-restricted-globals
const userUrl = new URL('/api/user', location)

export default function User ({ position, isMobile }) {
  const location = useLocation()
  const logout = useLogout()
  const token = location.state?.token || null
  const user = location.state?.init.user || null
  const theme = useTheme()
  const drawerWidth = theme.mixins.drawerWidth
  const { isLoading, error, sendRequest } = useHttp()
  const [userData, setUserData] = useState({})

  const getUser = useCallback(() => {
    sendRequest({
      token
      , url           : userUrl
      , manageResData : res => {
        setUserData(res.user)
      }
    })
  }, [token, sendRequest])

  useEffect(() => {
    if (!token) return logout()
    getUser()
  }, [getUser, token, logout])

  return (
    <Box component="main">
      {isMobile
        ? <>
          <MobileTopNav token={token} user={user} />
          <Toolbar />
        </>
        : <DesktopNav position={position} subPanelElements={[]} />
      }
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        width='100%'
        pl={!isMobile && `${drawerWidth}px`}
        // sx={theme => !isMobile && { [theme.breakpoints.down('lg')]: { ml: `${drawerWidth}px` } }}
      >
        <Typography
          sx={isMobile ? { mt: 2, mb: 1 } : { mt: 4, mb: 4 }}
          variant={isMobile ? 'h4' : 'h3'}
          align='center'
        >
          <Trans>Profile</Trans>
        </Typography>
        <Divider flexItem variant='middle' />
        <Box
          width={isMobile ? '90%' : '80%'}
          maxWidth='sm'
          // maxWidth={isMobile ? 'xs' : 'sm'}
         >
          <UserForm isMobile={isMobile} token={token} user={userData}/>
        </Box>
        {error && <Alert sx={{ mt: 2 }} severity="error">
          <Trans>There was an error: {error}.</Trans>
        </Alert>}
        {isMobile && <MobileBottomNav />}
        <FullPageProgress isOpen={isLoading} isMobile={isMobile} />
      </Box>
    </Box>
  )
}
