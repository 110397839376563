import { Box } from '@mui/material'
import { useMemo, useState } from 'react'

export const TableHeader = ({ children, actionLabel, action, Icon }) => {
  return (
    <Box
      sx={{
        backgroundColor : '#FAFAFA'
        , display         : 'flex'
        , alignItems      : 'center'
        , border          : '1px solid #CDCDCD'
      }}
    >
      <Box
        sx={{
          borderRight : '1px solid #CDCDCD'
          , padding     : '0.7rem'
          , marginRight : '0.2rem'
        }}
        display={Icon ? 'block' : 'none'}
      >
      <Icon/>
      </Box>
      <Box
        display="inline-flex"
        width="100%"
        paddingX={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>{children}</Box>{' '}
        <Box
      onClick={action}
          display={actionLabel ? 'block' : 'none'}
          sx={{
            borderLeft: '1px solid #CDCDCD'

            , padding : '0.7rem'
            , cursor  : 'pointer'
          }}
        >
          {actionLabel}
        </Box>
      </Box>
    </Box>
  )
}

export const TableBody = ({ status, totalCount, count }) => {
  const statusLabel = useMemo(
    () => (count === totalCount ? 'Complete' : 'Uncomplete'),
    [count, totalCount]
  )
  return (
    <Box
      sx={{
        borderBottom    : '1px solid #CDCDCD'
        , borderLeft      : '1px solid #CDCDCD'
        , borderRight     : '1px solid #CDCDCD'
        , backgroundColor : '#fff'
        , padding         : '0.7rem'
      }}
    >
      Status:{' '}
      <Box
        component="label"
        color={statusLabel === 'Complete' ? 'green' : 'orange'}
      >
        {statusLabel}
      </Box>
      <Box component="label" display={count && totalCount ? 'inline' : 'none'}>
        ({count}/{totalCount})
      </Box>
    </Box>
  )
}

export const SmallTable = ({ category, count, action, totalCount, icon, actionLabel }) => {
  return (
    <Box>
      <TableHeader Icon={icon} actionLabel={actionLabel} action={action} >
      {category}
      </TableHeader>
      <TableBody count={count} totalCount={totalCount} />
    </Box>
  )
}
