import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { BottomNavigation, BottomNavigationAction, Box, Paper } from '@mui/material'
import AddLocationOutlinedIcon from '@mui/icons-material/AddLocationOutlined'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
// import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined'
import CloseIcon from '@mui/icons-material/Close'
import MinimizeIcon from '@mui/icons-material/Minimize'
import MaximizeIcon from '@mui/icons-material/Maximize'

export default function MobileBottomNav ({
  openDialog
  , openAdminDialog
  , handleFormOpen
  , isPositioning
  , isFormOpen
  , isPositionSet
  , formReset
  , handlePositioningState
  , handleIsPositionSetState
  , openMobileLayers
  , toggleMobileLayers
  , handleSetFormNumber
  , mapRef
}) {
  const location = useLocation()
  const { state, state: { init } } = location
  const navigate = useNavigate()
  const { _ } = useLingui()
  const [value, setValue] = useState(0)

  return (
      <Box display='flex'>
      <Paper
        square
        elevation={3}
        sx={{
          position   : 'fixed'
          , bottom   : 0
          , left     : 0
          , right    : 0
          , zIndex   : theme => theme.zIndex.drawer + 1
          , height   : theme => theme.mixins.toolbar.minHeight
        }}>
          <BottomNavigation
            value={value}
            onChange={(_, newValue) => setValue(newValue)}
          >
          <BottomNavigationAction
            showLabel={false}
            label='Home'
            value='home'
            icon={<HomeOutlinedIcon />}
            onClick={() => {
              if (location.pathname === '/home') {
                handleFormOpen(false)
                toggleMobileLayers(false)
                handlePositioningState(false)
                handleIsPositionSetState(false)
                formReset()
                const newMarkers = mapRef.current.getElementsByClassName('new-markers')
                Array.from(newMarkers).forEach(marker => marker.remove())
                handleSetFormNumber(0)
              } else navigate('/home', { state })
              setValue(0)
            }}
          />
          {location.pathname === '/home' &&
            <>
              {/* <BottomNavigationAction
                label='Search'
                value='search'
                icon={<SearchOutlinedIcon />}
              /> */}
              <BottomNavigationAction
                label='Overlays'
                value='overlays'
                icon={openMobileLayers ? <CloseIcon /> : <LayersOutlinedIcon />}
                onClick={() => {
                  toggleMobileLayers()
                }}
              />
              <BottomNavigationAction
                label={isPositioning || isFormOpen
                  ? _(msg`Minimize`)
                  : isPositionSet
                    ? _(msg`Reopen`)
                    : _(msg`Add crash`)
                }
                value='addCrash'
                icon={isPositioning || isFormOpen
                  ? <MinimizeIcon />
                  : isPositionSet
                    ? <MaximizeIcon />
                    : <AddLocationOutlinedIcon />
                }
                onClick={() => {
                  if (isPositioning || isFormOpen) {
                    handlePositioningState(false)
                    handleFormOpen(false)
                  } else if (isPositionSet) {
                    handleFormOpen(true)
                  } else if (init.user.type === 1) {
                    openAdminDialog()
                  } else {
                    openDialog()
                  }
                }}
              />
            </>}
          </BottomNavigation>
        </Paper>
      </Box>
  )
}

// , {
//   text: isPositioning || isFormOpen
//     ? t`Cancel`
//     : isPositionSet
//       ? t`Reopen`
//       : t`Add crash`
//   , icon: isPositioning || isFormOpen
//     ? <Close color='primary' />
//     : isPositionSet
//       ? <ArrowForwardIosIcon color='primary' />
//       : <AddLocationAltIcon color='primary' />
//   , type     : 'button'
//   , onMobile : false
//   , action   : isPositioning || isFormOpen
//     ? () => {
//         setIsPositionSet(false)
//         setIsFormOpen(false)
//         handlePositioningState(false)
//         const newMarkers = mapRef.current.getElementsByClassName('new-markers')
//         console.log('HOME_NM', newMarkers)
//         Array.from(newMarkers).forEach(marker => marker.remove())
//         methods.reset(initFormObj) // Resets form
//         setFormNumber(0)
//       }
//     : isPositionSet
//       ? () => setIsFormOpen(true)
//       : user.type === 1
//         ? openAdminDialog
//         : openDialog
// }
