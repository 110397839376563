import { useRef, useState } from 'react'
import { Camera } from 'react-camera-pro'
import { Box, IconButton, styled, useTheme } from '@mui/material'
import LensIcon from '@mui/icons-material/Lens'
import FlashlightOnIcon from '@mui/icons-material/FlashlightOn'
import FlashlightOffIcon from '@mui/icons-material/FlashlightOff'
import FlipCameraIosIcon from '@mui/icons-material/FlipCameraIos'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'

export default function CameraBox ({ handlePicture, handleCameraClose }) {
  const theme = useTheme()
  const { _ } = useLingui()
  const [numberOfCameras, setNumberOfCameras] = useState(0)
  const camera = useRef(null)
  // const [devices, setDevices] = useState([])
  // const [activeDeviceId, setActiveDeviceId] = useState(undefined)
  const [torchToggled, setTorchToggled] = useState(false)

  const TopControls = styled(Box)(({ theme }) => ({
    position         : 'fixed'
    , display        : 'flex'
    , right          : 0
    , top            : theme.mixins.toolbar.minHeight
    , width          : '100%'
    , minHeight      : '40px'
    , height         : '5%'
    , background     : 'rgba(0, 0, 0, 0.8)'
    , zIndex         : theme.zIndex.drawer + 10
    , alignItems     : 'center'
    , justifyContent : 'center'
    , padding        : '5px'
    , boxSizing      : 'border-box'
  }))

  const BottomControls = styled(Box)(({ theme }) => ({
    position         : 'fixed'
    , display        : 'flex'
    , right          : 0
    , bottom         : theme.mixins.toolbar.minHeight
    , width          : '100%'
    , minHeight      : '100px'
    , height         : '10%'
    , background     : 'rgba(0, 0, 0, 0.8)'
    , zIndex         : theme.zIndex.drawer + 10
    , alignItems     : 'center'
    , justifyContent : 'space-between'
    , padding        : '25px'
    , boxSizing      : 'border-box'
  }))

  const StyledIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.common.white
  }))

  const StyledLensButton = styled(IconButton)(({ theme }) => ({
    color               : theme.palette.common.white
    , border            : '2px solid red'
  }))

  // useEffect(() => {
  //   (async () => {
  //     const devices = await navigator.mediaDevices.enumerateDevices()
  //     const videoDevices = devices.filter(i => i.kind === 'videoinput')
  //     setDevices(videoDevices)
  //   })()
  // })

  return (
    <Box
      zIndex={theme.zIndex.drawer + 1}
    >
      <TopControls>
        <StyledIconButton
          size='large'
          onClick={handleCameraClose}
        >
          <ExpandMoreIcon fontSize='inherit' />
        </StyledIconButton>
      </TopControls>
      <Camera
        ref={camera}
        aspectRatio="cover"
        facingMode="environment"
        numberOfCamerasCallback={i => setNumberOfCameras(i)}
        // videoSourceDeviceId={activeDeviceId}
        errorMessages={{
          noCameraAccessible   : _(msg`No camera device accessible. Please connect your camera.`)
          , permissionDenied   : _(msg`Permission denied. Please refresh and give camera permission.`)
          , switchCamera       : _(msg`It is not possible to switch camera to different one because there is only one video device accessible.`)
          , canvas             : _(msg`Canvas is not supported.`)
        }}
        videoReadyCallback={() => {
          console.log('Video feed ready.')
        }}
      />
      <BottomControls>
        {numberOfCameras <= 1 &&
          <StyledIconButton
            onClick={() => {
              if (camera.current) {
                const result = camera.current.switchCamera()
                console.log(result)
              }
            }}
          >
            <FlipCameraIosIcon />
          </StyledIconButton>
        }
        <StyledLensButton
          onClick={() => {
            if (camera.current) {
              const photo = camera.current.takePhoto()
              handlePicture(photo)
              handleCameraClose()
            }
          }}
        >
          <LensIcon />
        </StyledLensButton>
        <StyledIconButton
          sx={{ visibility: camera.current?.torchSupported ? 'visible' : 'hidden' }}
          onClick={() => {
            if (camera.current) {
              setTorchToggled(camera.current.toggleTorch())
            }
          }}
        >
          {torchToggled ? <FlashlightOffIcon /> : <FlashlightOnIcon />}
        </StyledIconButton>
      </BottomControls>
    </Box>
  )
}
