import { Box, Link, Typography } from '@mui/material'

export default function Copyright ({ left, bottom, bgcolor }) {
  return (
    <Box
      position='absolute'
      left={left}
      bottom={bottom}
      bgcolor={bgcolor}
      zIndex={theme => theme.zIndex.drawer - 1}
      pl='5px'
      pr='5px'
    >
      <Typography variant="body2" sx={{ font: '12px/20px Helvetica Neue,Arial,Helvetica,sans-serif' }}>
        {'© '}
        <Link href="https://www.fredeng.eu/">
          FRED Engineering
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </Box>
  )
}
