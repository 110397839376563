const columnFactory = ({ label, className = 'overload-header', rowSpanValueGetter = () => null }) => {
  return {
    field                : label
    , headerName         : label
    , headerClassName    : className
    , rowSpanValueGetter
    , flex            : 1
  }
}

const CONFIG_MAIN_KEY = 'label'

export const CRASH_ID_KEY = 'Crash id'
export const DATE_ID_KEY = 'Crash date'
export const UNIT_ID_KEY = 'units'
export const PEOPLE_ID_KEY = 'people'
export const COLLECTED_BY_ID = 'organization'

/**
 * Flattens the JSON to an object with keys for each label in elements.
 * @param {Object} data - The original JSON object.
 * @returns {Object} - The flattened object with labels as keys.
 */
function flattenLabels (data) {
  const result = {}

  for (const key in data) {
    result[key] = data[key]
    if (key in data) {
      const { elements } = data[key]
      elements.forEach(element => {
        result[element?.[CONFIG_MAIN_KEY]] = element
      })
    }
  }

  return result
}

// Example usage

export const getOrganization = (id, organizations = []) => {
  const value = organizations?.length && organizations?.find(org => org?.value === id)?.label
  return value
}

const getLabel = config => value => {
  const item = config[value]
  return item?.label ? item.label : value // Return value if label not found
}

export const getColumnsModel = configuration => {
  const columnsKeys = [COLLECTED_BY_ID, CRASH_ID_KEY, DATE_ID_KEY, UNIT_ID_KEY, PEOPLE_ID_KEY]
  const flattenConfig = flattenLabels(configuration)
  console.log('flatten config::', flattenConfig)
  const getLabelConfigured = getLabel(flattenConfig)

  const columns = columnsKeys?.map(id => columnFactory({ label: getLabelConfigured(id) }))
  const columnGroupingModel = []

  return { columns, columnGroupingModel }
}
