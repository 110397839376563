import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { messages as enMessages } from './locales/en/messages'
import { messages as esMessages } from './locales/es/messages'
import { messages as frMessages } from './locales/fr/messages'
import { messages as ptMessages } from './locales/pt/messages'
import './index.css'
import App from './App'

const container = document.getElementById('root')

// i18n.load('en', enMessages)
i18n.load({
  en   : enMessages
  , es : esMessages
  , fr : frMessages
  , pt : ptMessages
})

i18n.activate('en')

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/service-worker.js')
      .then(registration => {
        console.log('Service Worker registered:', registration)
      })
      .catch(error => {
        console.error('Service Worker registration failed:', error)
      })
  })
}

createRoot(container).render(
  <StrictMode>
    <BrowserRouter>
      <I18nProvider i18n={i18n}>
        <App />
      </I18nProvider>
    </BrowserRouter>
  </StrictMode>)
