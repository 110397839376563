import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState
} from 'react'
import { CRASH_ID_KEY } from '../pages/DataMerginAndValidation/utils'
import { distanzaDamerauLevenshtein } from '../pages/DataMerginAndValidation/functions/calculateDistance.js'
// import { FAKE_MATCHES } from './constants'

export const STATE_MACHINE = {
  CRASH_TABLE : 'CRASH_TABLE'
  , MATCH_TABLE : 'MATCH_TABLE'
  , MERGE_PAGE  : 'MERGE_PAGE'
  , MERGE_FORM  : 'MERGE_FORM'
}

/**
 * @typedef {Object} MergeContextType
 * @property {CrashReport[]} crashes - List of crashes.
 * @property {React.Dispatch<React.SetStateAction<CrashReport[]>>} setCrashes - Function to update crashes.
 * @property {StateMachineType} currentState - Current state in which the page is.
 * @property {Function} handleSelectedCrash - Function to handle selected crash.
 * @property {string} selectedCrash - Currently selected crash.
 * @property {Match[]} matches - List of matches.
 * @property {React.Dispatch<React.SetStateAction<Match[]>>} setMatches - Function to update matches.
 * @property {Object} selectedMatchDetail - Details of the selected match.
 * @property {Object} selectedCrashDetail - Details of the selected crash.
 * @property {Function} setSelectedCrashDetail - Function to set selected crash detail.
 * @property {Function} setSelectedMatchDetail - Function to set selected match detail.
 */

/** @type {React.Context<MergeContextType>} */
export const MergeContextProv = createContext({
  crashes                : []
  , setCrashes             : () => {}
  , currentState           : STATE_MACHINE.CRASH_TABLE
  , handleSelectedCrash    : () => {}
  , selectedCrash          : ''
  , matches                : []
  , setMatches             : () => {}
  , selectedMatchDetail    : {}
  , selectedCrashDetail    : {}
  , setSelectedCrashDetail : () => {}
  , setSelectedMatchDetail : () => {}
  , policePhotos           : []
  , setPolicePhotos        : () => {}
})

export const MERGE_CASES = {
  MERGE_ROAD   : 'merge_road'
  , MERGE_UNIT   : 'merge_unit'
  , MERGE_PERSON : 'merge_person'
  , SAVE_DATA    : 'save_data'
}

const formFactory = (police, hospital, insurance) => {
  return {
    police
    , hospital
    , insurance
  }
}

const CRASH_KEY = ['crash']
const ROAD_KEY = ['road']

export const UPDATE_CASES = {
  SAVE_CRASH  : 'save_crash'
  , SAVE_UNIT   : 'save_unit'
  , SAVE_PERSON : 'save_person'
}

const NAME_KEY = ['Name']
const SURNAME_KEY = ['Surname']

export const MODES = {
  MERGING    : 'merging'
  , VALIDATING : 'validating'
}

export const MergeContext = ({ children }) => {
  const [crashes, setCrashes] = useState([])
  const [mergedCrashes, setMergedCrashes] = useState([])
  const [currentState, setCurrentState] = useState(STATE_MACHINE.CRASH_TABLE)
  const [selectedCrash, setSelectedCrash] = useState(false)
  const [matches, setMatches] = useState([])
  const [selectedMatchDetail, setSelectedMatchDetail] = useState({})
  const [selectedCrashDetail, setSelectedCrashDetail] = useState({})
  const [mode, setMode] = useState(MODES.VALIDATING)
  const [policePhotos, setPolicePhotos] = useState([])

  useEffect(() => {
    if (Object?.values(selectedMatchDetail || {})?.some(x => x)) {
      setMode(MODES.MERGING)
    }
    if (Object.values(selectedCrashDetail || {})?.some(x => x) &&
        !Object.values(selectedMatchDetail || {})?.some(y => y)) {
      setMode(MODES.VALIDATING)
    }
  }, [selectedMatchDetail, selectedCrashDetail])

  function reducer (state, action) {
    switch (action.type) {
      case MERGE_CASES.MERGE_ROAD: {
        setCurrentState(STATE_MACHINE.MERGE_FORM)
        return {
          ...formFactory(
            {
              [CRASH_KEY] : selectedCrashDetail?.[CRASH_KEY]
              , [ROAD_KEY]  : selectedCrashDetail?.[ROAD_KEY]
            },
            {
              [CRASH_KEY] : selectedMatchDetail?.[CRASH_KEY]
              , [ROAD_KEY]  : selectedMatchDetail?.[ROAD_KEY]
            }
          )
          , type: UPDATE_CASES.SAVE_CRASH
        }
      }
      case MERGE_CASES.MERGE_UNIT: {
        setCurrentState(STATE_MACHINE.MERGE_FORM)
        const myUnit = selectedCrashDetail?.units?.[action.index]
        const { people, ...unit } = myUnit
        return {
          ...formFactory({ unit }, {})
          , type  : UPDATE_CASES.SAVE_UNIT
          , index : action.index
        }
      }
      case MERGE_CASES.MERGE_PERSON: {
        setCurrentState(STATE_MACHINE.MERGE_FORM)
        const myPerson = selectedCrashDetail?.people?.[action.index]
        const myFoundMatch =
          selectedMatchDetail?.people?.find(
            p =>
              distanzaDamerauLevenshtein(myPerson?.[NAME_KEY], p?.[NAME_KEY]) <
                3 &&
              distanzaDamerauLevenshtein(
                myPerson?.[SURNAME_KEY],
                p?.[SURNAME_KEY]
              ) < 3
          ) || {}

        return {
          ...formFactory(
            { person: { ...myPerson } },
            { person: { ...myFoundMatch } }
          )
          , type  : UPDATE_CASES.SAVE_PERSON
          , index : action.index
        }
      }

      default: {
        throw Error('Unknown action: ' + action.type)
      }
    }

    // throw Error('Unknown action: ' + action.type)
  }

  const [mergeState, dispatchMergeState] = useReducer(reducer)

  const handleBack = currentState => {
    const chronoOrder = Object.values(STATE_MACHINE)?.map(v => v)
    const actualStateKey = chronoOrder?.findIndex(item => item === currentState)
    const INDEX_TO_JUMP_BACKWARD = 1
    const prevKey = actualStateKey - INDEX_TO_JUMP_BACKWARD
    setCurrentState(chronoOrder?.[prevKey])
  }

  const handleSelectedCrash = (id, merged = false, images = []) => {
    setSelectedCrash(id)
    if (id) {
      setCurrentState(STATE_MACHINE.MATCH_TABLE)
      const myCrash = (merged ? mergedCrashes : crashes)?.find(
        c => c?.crash?.[CRASH_ID_KEY] === id
      )
      console.log('my crash::', myCrash)
      setSelectedCrashDetail({
        ...myCrash
        , people: myCrash?.people?.length
          ? myCrash?.people
          : myCrash?.units?.flatMap((u, ui) =>
            u?.people?.flatMap((p, pi) => ({ ...p, unitIndex: ui, personIndex: pi }))
          )
      })
    }
  }

  const updateSelectedCrashDetail = action => {
    switch (action.type) {
      case UPDATE_CASES.SAVE_CRASH: {
        setSelectedCrashDetail(old => ({ ...old, ...action.data }))
        setCurrentState(STATE_MACHINE.MERGE_PAGE)
        break
      }
      case UPDATE_CASES.SAVE_UNIT: {
        setSelectedCrashDetail(old => ({
          ...old
          , units: old?.units?.map((uni, index) => {
            if (index === action.index) {
              return { ...action.data?.unit, done: true }
            }
            return uni
          })
        }))
        setCurrentState(STATE_MACHINE.MERGE_PAGE)
        break
      }
      case UPDATE_CASES.SAVE_PERSON: {
        setSelectedCrashDetail(old => ({
          ...old
          , people: old?.people?.map((p, index) => {
            if (index === action.index) {
              return { ...action.data?.person, done: true }
            }
            return p
          })
        }))
        setCurrentState(STATE_MACHINE.MERGE_PAGE)
        break
      }
      default: {
        throw new Error('Not found action')
      }
    }
  }
  console.log('selected crash detail::', selectedCrashDetail)

  return (
    <MergeContextProv.Provider
      value={{
        crashes: crashes || []
        , setCrashes
        , currentState
        , setCurrentState
        , handleSelectedCrash
        , selectedCrash
        , matches
        , setMatches
        , setSelectedCrashDetail
        , setSelectedMatchDetail
        , selectedCrashDetail
        , selectedMatchDetail
        , mergeState
        , dispatchMergeState
        , updateSelectedCrashDetail
        , mergedCrashes
        , setMergedCrashes
        , handleBack
        , mode
        , policePhotos
        , setPolicePhotos
      }}
    >
      {children}
    </MergeContextProv.Provider>
  )
}

export function useMergeContext () {
  const context = useContext(MergeContextProv)
  if (context === undefined) {
    throw new Error('Context must be used within a Provider')
  }
  return context
}
