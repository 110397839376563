import { createContext, useContext } from 'react'

const RoutesContext = createContext()

export const RoutesProvider = ({ children, routes }) => {
  return (
    <RoutesContext.Provider value={routes}>
      {children}
    </RoutesContext.Provider>
  )
}

export const useRoutes = () => {
  const context = useContext(RoutesContext)
  if (!context) {
    throw new Error('useRoutes must be used within a RoutesProvider')
  }
  return context
}
