import { useMergeContext } from '../../../store/mergeContext'
import { useTheme } from '@emotion/react'
import { Box, Stack, Paper, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useState, useEffect, useMemo } from 'react'
import { getConfigOptions, mapObjectToLabels } from '../../Statistics/utils'
import { useLocation } from 'react-router-dom'
import {
  COLLECTED_BY_ID,
  CRASH_ID_KEY,
  getColumnsModel,
  getOrganization
} from '../utils'
import { Trans } from '@lingui/react'

export const CrashTable = () => {
  const { crashes, handleSelectedCrash, mergedCrashes } = useMergeContext()

  const [columns, setColumns] = useState([])
  const [columnGroupingModel, setColumnGroupingModel] = useState([])
  const [rows, setRows] = useState([])
  const [validatedRow, setValidatedRows] = useState([])
  const [user, setUser] = useState({})
  const location = useLocation()
  const init = useMemo(
    () => location.state?.init || {},
    [location.state?.init]
  )
  const organizations = useMemo(
    () => location.state?.init?.organizations || {},
    [location]
  )
  console.log('organizations::', organizations)

  const theme = useTheme()

  useEffect(() => {
    const { configuration } = init

    const configOptions = getConfigOptions(configuration)

    const mappedCrashes = mergedCrashes.map(crash =>
      mapObjectToLabels(crash, configOptions)
    )

    const preparedRows = mappedCrashes.map(
      crash => (
        console.log('crash::', crash),
        {
          ...crash.crash
          , units: Object.entries(crash?.units)?.length
          , people:
            Object.values(crash?.units)?.flatMap(v =>
              Object.values(v?.people)?.flatMap(p => p)
            )?.length + Object.values(crash?.people || {})?.length
          , [COLLECTED_BY_ID]: getOrganization(
            crash?.crash?.[COLLECTED_BY_ID],
            organizations
          )
        }
      )
    )

    console.log('prepared rows :: ', preparedRows)

    setValidatedRows(
      preparedRows?.map((row, index) => ({ ...row, id: index }))
    )
  }, [init, mergedCrashes, organizations])

  useEffect(() => {
    const { configuration, user } = init
    setUser(user)

    const configOptions = getConfigOptions(configuration)

    const mappedCrashes = crashes.map(crash =>
      mapObjectToLabels(crash, configOptions)
    )

    const preparedRows = mappedCrashes.map(crash => ({
      ...crash.crash
      , units: Object.entries(crash?.units)?.length
      , people:
        Array.isArray(Object.values(crash?.units)) &&
        Object.values(crash?.units)?.flatMap(uni =>
          Object.values(uni?.people)?.flatMap(p => p)
        )?.length
      , [COLLECTED_BY_ID]: getOrganization(
        crash?.crash?.[COLLECTED_BY_ID],
        organizations
      )
    }))

    setRows(preparedRows?.map((row, index) => ({ ...row, id: index })))

    const { columns } = getColumnsModel(configuration)

    setColumnGroupingModel()

    setColumns(columns)
  }, [init, crashes, organizations])

  return (
    <Stack direction="column">
      <Typography sx={{ width: '100%', mt: 2 }} variant="h3">
        Select a crash to validate
      </Typography>
      <Paper sx={{ pt: -1, mb: 2 }}>
        <DataGrid
          rowSelection
          onRowClick={e => handleSelectedCrash(e?.row?.[CRASH_ID_KEY])}
          showColumnVerticalBorder
          showCellVerticalBorder
          rows={rows}
          columns={columns}
          editMode="row"
          columnGroupingModel={columnGroupingModel}
          pageSizeOptions={[5]}
          sx={{
            border                        : 0
            , '& .MuiDataGrid-menuIcon > *' : {
              color: theme.palette.primary.contrastText
            }
          }}
        />
      </Paper>

      <Typography sx={{ width: '100%', mt: 2 }} variant="h3">
        Validated crashes
      </Typography>
      <Paper sx={{ pt: -1, mb: 2 }}>
        <DataGrid
          rowSelection
          onRowClick={e => handleSelectedCrash(e?.row?.[CRASH_ID_KEY], true, crashes)}
          showColumnVerticalBorder
          showCellVerticalBorder
          rows={validatedRow}
          columns={columns}
          editMode="row"
          columnGroupingModel={columnGroupingModel}
          pageSizeOptions={[5]}
          sx={{
            border                        : 0
            , '& .MuiDataGrid-menuIcon > *' : {
              color: theme.palette.primary.contrastText
            }
          }}
        />
      </Paper>
    </Stack>
  )
}
