import { useState, useMemo, useCallback, useEffect, useContext } from 'react'
import { Box, CssBaseline, Typography, Grid, Button } from '@mui/material'
import DesktopNav from '../../components/desktop/DesktopNav'
import { useNavigate, useLocation } from 'react-router-dom'
import useLogout from '../../hooks/useLogout'
import useHttp from '../../hooks/useHttp'
import { STATE_MACHINE, useMergeContext } from '../../store/mergeContext'
import { CrashTable } from './subPages/CrashTable'
import { useTheme } from '@emotion/react'
import { MatchTable } from './subPages/MatchTable'
import { MergePage } from './subPages/MergePage'
import { MergeForm } from './subPages/MergeForm'
import { ArrowLeft } from '@mui/icons-material'

// eslint-disable-next-line no-restricted-globals
const allCrashesUrl = new URL('/api/crash/all', location)

const ORGANIZATION_ID_TO_GET = 1

export const DataMergingAndValidation = ({ position, isMobile }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const logout = useLogout()
  const token = location.state?.token || null
  const init = useMemo(
    () => location.state?.init || {},
    [location.state?.init]
  )
  const theme = useTheme()
  const drawerWidth = theme.mixins.drawerWidth
  const { sendRequest } = useHttp()

  const { setCrashes, currentState, setMergedCrashes, handleBack } = useMergeContext()

  const getCrashes = useCallback(async () => {
    const allCrashesReducedUrl = new URL(allCrashesUrl)
    allCrashesReducedUrl.searchParams.set(
      'organization',
      ORGANIZATION_ID_TO_GET
    )
    allCrashesReducedUrl.searchParams.set('merged', 0)
    await sendRequest({
      token
      , url           : allCrashesReducedUrl
      , manageResData : res => setCrashes(res?.crashes)
    })
  }, [token, sendRequest, setCrashes])

  const getMergedCrashes = useCallback(async () => {
    const allCrashesReducedUrl = new URL(allCrashesUrl)
    allCrashesReducedUrl.searchParams.set('merged', 1)
    await sendRequest({
      token
      , url           : allCrashesReducedUrl
      , manageResData : res => setMergedCrashes(res?.crashes)
    })
  }, [token, sendRequest, setMergedCrashes])

  const fetchCrashes = async () => {
    await getMergedCrashes()
    await getCrashes()
  }

  useEffect(() => {
    getCrashes()
  }, [getCrashes])
  console.log('init', init)

  useEffect(() => {
    getMergedCrashes()
  }, [getMergedCrashes])

  return (
    <Box component="main">
      <DesktopNav position={position} subPanelElements={[]} />

      <Box>
        <CssBaseline />
        <Box
          ml={`${drawerWidth}px`}
          mt={2}
          pl={2}
          pr={2}
          display="flex"
          flexDirection="column"
          alignItems="stretch"
          gap={3}
          sx={{
            '& .overload-header': {
              backgroundColor : theme.palette.primary.main
              , color           : theme.palette.primary.contrastText
            }
          }}
        >
          <Grid sx={{ display: currentState === STATE_MACHINE.CRASH_TABLE ? 'none' : 'block' }}>
            <Button onClick={() => { handleBack(currentState) }} variant="text">
              <ArrowLeft></ArrowLeft> Back
            </Button>
          </Grid>
          {currentState === STATE_MACHINE.CRASH_TABLE && <CrashTable />}

          {currentState === STATE_MACHINE.MATCH_TABLE && <MatchTable />}

          {currentState === STATE_MACHINE.MERGE_PAGE && (
            <MergePage fetchCrashes={fetchCrashes} />
          )}

          {currentState === STATE_MACHINE.MERGE_FORM && <MergeForm />}
        </Box>
      </Box>
    </Box>
  )
}
