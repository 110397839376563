import { Fragment, useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { msg, Trans, t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import {
  Alert
  , Box
  , Divider
  , Paper, Skeleton
  , Typography
  , useTheme
} from '@mui/material/'
import PeopleIcon from '@mui/icons-material/People'
import SettingsIcon from '@mui/icons-material/Settings'
// Three different icons to choose from
// import HubIcon from '@mui/icons-material/Hub'
import Groups3Icon from '@mui/icons-material/Groups3'
// import WorkspacesIcon from '@mui/icons-material/Workspaces'
import useHttp from '../hooks/useHttp'
import useLogout from '../hooks/useLogout'
import DesktopNav from '../components/desktop/DesktopNav'
import OrganizationIcon from '../components/OrganizationIcon'
import UsersTable from '../components/tables/UsersTable'
import ConfTable from '../components/tables/ConfigsTable'
import OrganizationsTable from '../components/tables/OrganizationsTable'
import { interpolateOrgs } from '../utils'
import { Snack } from '../components/Snack'

// eslint-disable-next-line no-restricted-globals
const getUsersUrl = new URL('/api/users', location)
// eslint-disable-next-line no-restricted-globals
const postUserUrl = new URL('/api/user', location)
// eslint-disable-next-line no-restricted-globals
const structUrl = new URL('/api/structure', location)
// eslint-disable-next-line no-restricted-globals
const initUrl = new URL('/api/init', location)
// eslint-disable-next-line no-restricted-globals
const organizationsUrl = new URL('/api/organizations', location)
// eslint-disable-next-line no-restricted-globals
const addOrganizationUrl = new URL('/api/organization/add', location)
// eslint-disable-next-line no-restricted-globals
const modifyOrganizationUrl = new URL('/api/organization/modify', location)
// eslint-disable-next-line no-restricted-globals
const deleteOrganizationUrl = new URL('/api/organization/delete', location)

export default function Admin ({ position, isMobile }) {
  const navigate = useNavigate()
  const location = useLocation()
  const logout = useLogout()
  const token = location.state?.token || null
  const init = location.state?.init || {}
  const { configuration } = init
  const theme = useTheme()
  const { _ } = useLingui()
  const drawerWidth = theme.mixins.drawerWidth
  const { isLoading, error, sendRequest } = useHttp()
  const [users, setUsers] = useState([])
  const [config, setConfig] = useState({})
  const [message, setMessage] = useState({})
  const [showSub, setShowSub] = useState('users')
  const [organizations, setOrganizations] = useState([])

  const TableSkel = () => ( // ? <CircularProgress sx={{ mt: 2 }} />
    <>
      <Skeleton width='100%' sx={{ mt: 2, fontSize: '2rem' }} />
      {Array.from({ length: 5 }).map((_, i) => <Skeleton key={i} width='100%' />)}
      {/* <Skeleton width='100%' sx={{ fontSize: '2rem' }} /> */}
    </>
  )

  const subPanelElements = [
    {
      label      : t`Users management`
      , icon     : <PeopleIcon color='primary' />
      , type     : 'button'
      , onMobile : false
      , access   : [1, 2]
      , action   : () => setShowSub('users')
    }
    , {
      label       : t`Organizations management`
      , icon     : <Groups3Icon color='primary' />
      , type     : 'button'
      , onMobile : false
      , access   : [1]
      , action   : () => setShowSub('organizations')

    }
    , {
      label       : t`Form configuration`
      , icon     : <SettingsIcon color='primary' />
      , type     : 'button'
      , onMobile : false
      , access   : [1]
      , action   : () => setShowSub('configuration')
    }
  ]

  const getOrganizations = useCallback(() => {
    sendRequest({
      url             : organizationsUrl
      , manageResData : res => {
        setOrganizations(res.organizations)
      }
    })
  }, [sendRequest])

  const getUsers = useCallback(() => {
    sendRequest({
      token
      , url           : getUsersUrl
      , manageResData : res => {
        setUsers(res.users)
      }
    })
  }, [token, sendRequest])

  useEffect(() => {
    if (!token) return logout()
    if (isMobile) return navigate('/home', { state: { token } })
    getUsers()
    getOrganizations()
  }, [isMobile, navigate, token, logout, getUsers, getOrganizations])

  const modifyUser = data => {
    sendRequest({
      token
      , url           : postUserUrl
      , httpBody      : data
      , httpMethod    : 'POST'
      , manageResData : res => {
        if (res.result.includes('success')) {
          getUsers()
          setMessage(old => ({ ...old, user: _(msg`User successfully modified!`) }))
        }
      }
    })
  }

  const addOrModifyOrganization = data => {
    let url = modifyOrganizationUrl
    let message = _(msg`Organization successfully modified!`)
    if (data.isNew) {
      url = addOrganizationUrl
      message = _(msg`Organization successfully added!`)
    }

    const body = { ...data, basic: data.basic_id }
    sendRequest({
      url
      , token
      , httpBody      : body
      , httpMethod    : 'POST'
      , manageResData : res => {
        if (res.result.includes('success')) {
          getOrganizations()
          setMessage(old => ({ ...old, organization: message }))
        }
      }
    })
  }

  useEffect(() => {
    let config = {}
    Object.keys(configuration)
      .sort((a, b) => configuration[a].position - configuration[b].position)
      .forEach(key => {
        config = {
          ...config
          , [key]: configuration[key].elements
            .map((element, i) => ({ ...element, id: i + 1, section: key }))
        }
      })
    console.log('CONF', config)
    setConfig(config)
  }, [configuration])

  const deleteOrganization = id => {
    const message = _(msg`Organization successfully deleted!`)
    sendRequest({
      token
      , url           :  deleteOrganizationUrl
      , httpMethod    : 'POST'
      , httpBody      : { id }
      , manageResData : res => {
        if (res.result.includes('success')) {
          getOrganizations()
          setMessage(old => ({ ...old, organization: message }))
        }
      }
    })
  }

  const modifyField = (label, data) => {
    let body
    const group = data.section
    if (data.action && data.action === 'delete') {
      body = { action: 'delete', group, label }
    } else if (data.isNew) {
      body = { action: 'add', group, data, label }
    } else {
      body = { action: 'update', group, data, label }
    }
    console.log('BODDDY', body)
    const options = {
      token
      , url           : structUrl
      , httpBody      : body
      , httpMethod    : 'POST'
      , manageResData : res => {
        if (res.result.includes('success')) {
          // Refresh init
          sendRequest({
            token
            , url           : initUrl
            , manageResData : res => {
              console.log('INIT', res)
              let { error: initError, error_code, result, organizations, ...init } = res // { configuration, defaults, user, totalCrashes }
              init = { ...init, organizations: interpolateOrgs(organizations) }
              navigate('.', { state: { token, init } })
              setMessage(old => ({ ...old, [group]: _(msg`Field successfully modified!`) }))
            }
          })
        }
      }
    }
    console.log('OPTS', options)
    sendRequest(options)
  }

  const handleResetMessage = () => setMessage({})

  return (
    <Box component="main">
      <DesktopNav position={position} subPanelElements={subPanelElements} />
      <Box
        ml={`${drawerWidth}px`}
        mt={4}
        mb={4}
        pl={2}
        pr={2}
        display='flex'
        flexDirection='column'
        alignItems='center'
        gap={3}
        sx={{
          '& .overload-header': {
            backgroundColor   : theme.palette.primary.main
            , color           : theme.palette.primary.contrastText
          }
        }}
      >
        {isLoading
          ? <TableSkel />
          : <>
            {
              {
                users:
                  <>
                    <Typography sx={{ width: '100%', mt: 2 }} variant="h3">
                      <Trans>Users list</Trans>
                    </Typography>
                    <Divider flexItem />
                    <Paper sx={{ width: '100%' }}>
                      <UsersTable usersRows={users} modifyUser={modifyUser} error={error} />
                    </Paper>
                  </>
                , organizations:
                  <>
                    <Typography sx={{ width: '100%', mt: 2 }} variant="h3">
                      <Trans>Organizations list</Trans>
                    </Typography>
                    <Divider flexItem />
                    <Paper sx={{ width: '100%' }}>
                      <OrganizationsTable
                        organizations={organizations}
                        addOrModifyOrganization={addOrModifyOrganization}
                        deleteOrganization={deleteOrganization}
                      />
                    </Paper>
                </>
                , configuration:
                    <>
                    <Typography sx={{ width: '100%', mt: 2 }} variant="h3">
                      <Trans>Form configuration</Trans>
                    </Typography>
                    <Divider flexItem />
                    <Box mt={3} display='flex' justifyContent='space-evenly' width='100%'>
                      {organizations
                        .filter(org => org.id < 4)
                        .map(org => (
                          <Box key={org.id} display='flex'>
                            <OrganizationIcon organization={org.description} />
                            <Typography ml={0.5}>{` ${org.description}`}</Typography>
                          </Box>
                        ))}
                    </Box>
                      {Object.keys(config).map((section, i) => (
                        <Fragment key={section}>
                          {isLoading
                            ? <TableSkel />
                            : <>
                              <Typography textTransform='uppercase' sx={{ mt: 2, mb: -1 }} variant="h4" align="center">
                                <Trans>{section}</Trans>
                              </Typography>
                              <Divider variant='middle' flexItem />
                              <Paper sx={{ width: '100%', mb: i === Object.keys(config).length - 1 ? 4 : 0 }}>
                                <ConfTable
                                  section={section}
                                  sectionRows={config[section]}
                                  modifyField={modifyField}
                                  message={message[section]}
                                  handleResetMessage={handleResetMessage}
                                  error={error}
                                />
                              </Paper>
                            </>}
                        </Fragment>
                      ))}
                    </>
              }[showSub]
            }
          </>}
          <Snack message={message.user || message.organization} setMessage={setMessage} error={error} />
        {/* {(message.user || message.organization) &&
          <Alert sx={{ mr: 'auto', ml: 'auto', mt: 2, mb: 2 }} severity='success'>
            {message.user || message.organization}
          </Alert>}
        {error &&
          <Alert sx={{ mr: 'auto', ml: 'auto', mt: 2, mb: 2 }} severity="error">
            <Trans>There was an error: {error}.</Trans>
          </Alert>} */}
      </Box>
    </Box>
  )
}
