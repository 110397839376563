export function distanzaDamerauLevenshtein (s1, s2) {
  const len1 = s1.length
  const len2 = s2.length

  // Creazione della matrice
  const d = []
  for (let i = 0; i <= len1; i++) {
    d[i] = []
    d[i][0] = i
  }
  for (let j = 0; j <= len2; j++) {
    d[0][j] = j
  }

  for (let i = 1; i <= len1; i++) {
    for (let j = 1; j <= len2; j++) {
      const costo = s1.charAt(i - 1) === s2.charAt(j - 1) ? 0 : 1

      d[i][j] = Math.min(
        d[i - 1][j] + 1, // Cancellazione
        d[i][j - 1] + 1, // Inserimento
        d[i - 1][j - 1] + costo // Sostituzione
      )

      if (
        i > 1 && j > 1 &&
                s1.charAt(i - 1) === s2.charAt(j - 2) &&
                s1.charAt(i - 2) === s2.charAt(j - 1)
      ) {
        d[i][j] = Math.min(
          d[i][j],
          d[i - 2][j - 2] + costo // Trasposizione
        )
      }
    }
  }

  return d[len1][len2]
}
