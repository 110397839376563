import {
  Autocomplete,
  Grid,
  Input,
  MenuItem,
  Select,
  TextField,
  InputLabel
} from '@mui/material'
import { Controller } from 'react-hook-form'

/**
 * @typedef {Object} DatiStruttura
 * @property {string} [registerName]
 * @property {"1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "10" | "11" | "12"} [sm]
 * @property {"1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "10" | "11" | "12"} [md]
 * @property {"1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "10" | "11" | "12"} [lg]
 * @property {"1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "10" | "11" | "12"} [xs]
 * @property {string} [label]
 * @property {string} [extendedLabel]
 * @property {boolean} [shouldRegister]
 * @property {("separator" | InputType)} [type]
 * @property {{ label: string; value: string }[]} [options]
 * @property {RegisterOptions} [registerOptions]
 * @property {string} [description]
 * @property {{ value: string; description: string; label: string }[]} [radioOptions]
 * @property {boolean} [isMulti]
 * @property {boolean} [flagSolaLettura]
 * @property {boolean} [flagObbligatorio]
 * @property {string} [regex]
 * @property {boolean} [flagMultiLingua]
 * @property {{
 *   additionalFields?: DatiStruttura[],
 *   buttonsLabel?: { add: string; remove: string },
 *   hideButton?: boolean
 * }} [multiOptions]
 * @property {{
 *   additionalFields?: DatiStruttura[]
 * }} [nestedOptions]
 */

import { Fragment } from 'react'

/**
 * @param {{ inputs: DatiStruttura[], register: Function }} params
 */

const ControlledAutocomplete = ({
  options = [],
  renderInput,
  getOptionLabel,
  onChange: ignored,
  control,
  defaultValue,
  name,
  renderOption
}) => {
  return (
    <Controller
      required
      render={({ field: { onChange, value, ...props } }) => (
        console.log('value::', value),
        (
          <Autocomplete
            options={options?.filter(
              fi =>
                fi?.value !== null &&
                fi?.value !== undefined &&
                fi?.value !== ''
            )}
            getOptionLabel={getOptionLabel}
            freeSolo
            renderOption={renderOption}
            renderInput={renderInput}
            onChange={(_, data) => onChange(data?.value || data)}
            autoSelect
            value={value}
            {...props}
          />
        )
      )}
      onChange={([, data]) => data.toString()}
      defaultValue={defaultValue?.toString()}
      name={name}
      control={control}
    />
  )
}
const ReactHookFormSelect = ({
  name,
  label,
  control,
  defaultValue,
  children,
  disabled,
  ...props
}) => {
  const labelId = `${name}-label`
  return (
      <Fragment>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        render={
         ({ field : { value, onChange } }) => <Select disabled={disabled} sx={{ width: '90%' }} onChange={onChange} value={value} labelId={labelId} label={label}>
            {children}
          </Select>
        }
        name={name}
        control={control}
      />
</Fragment>
  )
}
const getInputByType = ({ input, ref, registerSpread, control }) => {
  if (input?.type === 'autocomplete') {
    return (
      <ControlledAutocomplete
        control={control}
        name={input?.registerName}
        options={input?.options}
        // freeSolo
        getOptionLabel={option => (
          console.log('option::', option),
          option?.label?.toString() || option?.toString()
        )}
        renderInput={params => <TextField {...params} />}
      />
    )
  }

  if (input?.type === 'select') {
    return (
      <ReactHookFormSelect name={input?.registerName} control={control} defaultValue='' disabled={input?.disabled} variant='outlined' >
        {input?.options?.map(opt => (
          <MenuItem value={opt?.value}>{opt?.label}</MenuItem>
        ))}
      </ReactHookFormSelect>
    )
  }

  return <TextField sx={{ width: '90%' }} ref={ref} disabled {...registerSpread} />
}

export const registerInputs = ({ inputs, register, control }) => {
  return (
    <Fragment>
      {inputs?.map(input => {
        console.log('options::', input?.options)
        const { ref, ...registerSpread } =
          input?.type === 'autocomplete'
            ? { ref: null, null: null }
            : register(input?.registerName)
        return (
          <Grid xs={input?.xs} textAlign="center" marginY="1rem">
            {getInputByType({ input, ref, registerSpread, control })}
          </Grid>
        )
      })}
    </Fragment>
  )
}
