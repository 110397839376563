import { t } from '@lingui/macro'
import { green, grey, orange, red, yellow } from '@mui/material/colors'

export const SEVERITIES = () => [
  { value: 0, fill: grey[700], text: t`No info` }
  , { value: 1, fill: green.A400, text: t`No injuries` }
  , { value: 2, fill: yellow.A700, text: t`Slight injuries` }
  , { value: 3, fill: orange[800], text: t`Serious injuries` }
  , { value: 4, fill: red.A700, text: t`Fatal` }
]

// export const SEVERITIES = () => [
//   { value: 0, fill: '#606161', text: t`No info` }
//   , { value: 1, fill: '#09D007', text: t`No injuries` }
//   , { value: 2, fill: '#DDDD00', text: t`Slight injuries` }
//   , { value: 3, fill: '#DD7E00', text: t`Serious injuries` }
//   , { value: 4, fill: '#DD0000', text: t`Fatal` }
// ]

// export const SEVERITIES = [
//   { value: 0, fill: '#606161', text: 'No info' }
//   , { value: 1, fill: '#1AF007', text: 'No injuries' }
//   , { value: 2, fill: '#EEEE00', text: 'Slight injuries' }
//   , { value: 3, fill: '#EE8F00', text: 'Serious injuries' }
//   , { value: 4, fill: '#EE0000', text: 'Fatal' }
// ]
