import { Box, FormControl, InputAdornment, InputLabel, ListSubheader, MenuItem, Select, TextField, Typography } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileDateTimePicker, DateTimePicker, renderTimeViewClock, DatePicker, MobileDatePicker } from '@mui/x-date-pickers/'
import { useFormContext, Controller } from 'react-hook-form'

export default function FilterFormElement ({ elem, isMobile, form, formList }) {
  const { control } = useFormContext()

  if (elem.type === 'datetime') {
    return (
      <Box width='100%' display='flex' gap={1}>
        {['From', 'To'].map(term => (
          <FormControl
            key={`${form.type}-${elem.type}-${elem.label}-${term}`}
            sx={{ flex: 1 }}
            margin={elem.margin || 'dense'}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                name={`${formList[form.number]}.${elem.label}-${term}`}
                control={control}
                render={({ field: { value, onChange } }) => (
                  isMobile
                    ? <MobileDateTimePicker
                      label={`${elem.label} - ${term}`}
                      disableFuture
                      value={value}
                      onAccept={date => {
                        onChange(date)
                      }}
                    />
                    : <DateTimePicker
                      label={`${elem.label} - ${term}`}
                      disableFuture
                      value={value}
                      onAccept={date => {
                        onChange(date)
                      }}
                      viewRenderers={{
                        hours     : renderTimeViewClock
                        , minutes : renderTimeViewClock
                        , seconds : renderTimeViewClock
                      }}
                    />
                )
                }
              />
            </LocalizationProvider>
          </FormControl>
        ))}
      </Box>
    )
  }

  if (elem.type === 'date') {
    return (
      <Box width='100%' display='flex' gap={elem.stack ? 0 : 1} flexDirection={elem.stack ? 'column' : 'row'}>
        {['From', 'To'].map(term => (
          <FormControl
            key={`crash-${elem.type}-${elem.label}-${term}`}
            sx={{ flex: 1 }}
            margin={elem.margin || 'dense'}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                name={`${formList ? formList[form.number] : ''}.${elem.label}-${term}`}
                control={control}
                render={({ field: { value, onChange } }) => (
                  isMobile
                    ? <MobileDatePicker
                      label={`${elem.label} - ${term}`}
                      disableFuture
                      value={value}
                      onAccept={date => {
                        onChange(date)
                      }}
                    />
                    : <DatePicker
                      label={`${elem.label} - ${term}`}
                      disableFuture
                      value={value}
                      onAccept={date => {
                        onChange(date)
                      }}
                    />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        ))}
      </Box>
    )
  }

  if (elem.type === 'select') {
    return (
      <Box width='100%'>
        <FormControl fullWidth key={`${elem.type}-${elem.label}`} margin={elem.margin || 'dense'}>
          <InputLabel id={`select-${elem.label}`}>{elem.label}</InputLabel>
          <Controller
            name={`${formList ? formList[form.number] : ''}.${elem.label}`}
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                labelId={`select-${elem.label}`}
                id={`select-${elem.label}`}
                label={elem.label}
                value={field.value}
                onChange={field.onChange}
                startAdornment={elem.prefix && <InputAdornment>{elem.prefix}&nbsp;</InputAdornment>}
              >
                {elem.options.map(opt => {
                  if (field.name.includes('Nationality') || field.name.includes('country')) {
                    return (
                      <MenuItem key={opt.label} value={opt.value}>
                        <Box display='flex'>
                          <Box
                            height='1.5rem'
                            border='1px solid #c4c4c4'
                            borderRadius={1}
                            sx={{
                              aspectRatio           : '4/3'
                              , backgroundImage     : `url('${opt.flag}')`
                              , backgroundSize      : 'cover'
                              , backgroundPosition  : 'center center'
                            }}
                            >
                            {/* <img height='100%' src={`${opt.flag}`} style={{ border: '1px solid #c4c4c4' }} /> */}
                          </Box>
                          <Typography ml={3}>{opt.label}</Typography>
                        </Box>
                      </MenuItem>
                    )
                  } else {
                    return opt.value && opt.value % 100 === 0
                      ? <ListSubheader key={`${opt.label}-sub`}>{opt.label}</ListSubheader>
                      : <MenuItem key={opt.label} value={opt.value}>{opt.label}</MenuItem>
                  }
                })}
              </Select>
            )}
          />
        </FormControl>
      </Box>
    )
  }

  return (
    <FormControl fullWidth key={`${elem.type}-${elem.label}`} margin={elem.margin || 'dense'}>
      <Controller
        name={`${formList ? formList[form.number] : ''}.${elem.label}`}
        control={control}
        render={({ field }) => (
          <TextField
            // margin='normal'
            fullWidth
            required={elem.isRequired}
            id={elem.label}
            label={elem.label}
            value={field.value}
            onChange={field.onChange}
            {...field}
          />
        )}
      />
    </FormControl>
  )
}
