import { Trans } from '@lingui/macro'
import { Box, MenuItem, TextField, Typography } from '@mui/material/'
import { BarChart } from '@mui/x-charts/BarChart'
import { LineChart } from '@mui/x-charts/LineChart'
import { PieChart } from '@mui/x-charts/PieChart'
import { axisClasses } from '@mui/x-charts/ChartsAxis'
import useWindowSize from '../hooks/useWindowSize'

export default function Chart ({ chart, index, handleCharts, width }) {
  const windowSize = useWindowSize()

  return (
    <Box width={width} display='flex' flexDirection='column' alignItems='center' p={width === '100%' ? 2 : 0}>
      <Typography align='center' variant='h6' width={400}>
        <Trans>{chart.title}</Trans>
      </Typography>
      <Box width='100%' position='relative' mt={1} mb={-2}>
        <TextField
          select
          size='small'
          value={chart.type}
          onChange={event => handleCharts(event, index)}
          // onChange={event => handleCharts(old => old.map(el => el.apiNum === index + 1
          //   ? ({ ...el, type: event.target.value })
          //   : el
          // ))}
          label="Chart type"
          sx={{ minWidth: 100, float: 'right', mr: 2 }}
        >
          <MenuItem value="line"><Trans>line</Trans></MenuItem>
          <MenuItem value="bar"><Trans>bar</Trans></MenuItem>
          <MenuItem value="pie"><Trans>pie</Trans></MenuItem>
        </TextField>
      </Box>
      {
        {
          bar: <BarChart
            xAxis={[
              {
                data               : chart.data.map(el => el.label)
                , scaleType          : 'band'
                , label              : chart.xLabel
                , labelStyle         : chart.xLabelStyle
                , colorMap           : chart.colorMap
                , tickPlacement      : chart.tickPlacement
                , tickLabelPlacement : chart.tickLabelPlacement
                , tickLabelStyle     : chart.tickLabelStyle

              }
            ]}
            yAxis={[{ label: chart.yLabel, labelStyle: chart.yLabelStyle }]}
            series={[{ data: chart.data.map(el => el.value) }]}
            height={windowSize.height * 0.4 + (chart.margin?.bottom ?? 0)}
            margin={chart.margin}
            sx={{
              [`.${axisClasses.left} .${axisClasses.label}`]: {
              // Move the y-axis label with CSS
                transform: 'translateX(-0.5rem)'
              }
            }}
          />
          , line: <LineChart
            xAxis={[
              {
                data             : chart.data.map(el => el.label)
                , scaleType      : 'band'
                , label          : chart.xLabel
                , labelStyle     : chart.xLabelStyle
              }
            ]}
            yAxis={[{ label: chart.yLabel, labelStyle: chart.yLabelStyle }]}
            series={[{ data: chart.data.map(el => el.value) }]}
            height={windowSize.height * 0.4 + (chart.margin?.bottom ?? 0)}
            margin={chart.margin}
            sx={{
              [`.${axisClasses.left} .${axisClasses.label}`]: {
              // Move the y-axis label with CSS
                transform: 'translateX(-0.5rem)'
              }
            }}
          />
          , pie: <PieChart
            colors={chart?.colorMap?.colors}
            series={[{
              data          : chart.data.map(el => ({ value: el.value, label: el?.label }))
              , outerRadius : Math.min((windowSize.width * 0.1), (windowSize.height * 0.4))
              , cx            : width === '100%' ? '33%' : '50%'
            }]}
            height={windowSize.height * 0.4 + (chart.margin?.bottom ?? 0)}
          />
        }[chart.type]
      }
    </Box>
  )
}
