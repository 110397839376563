import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import {
  Alert
  , Box
  , CssBaseline
  , Divider
  , Toolbar
  , Typography
  , useTheme
} from '@mui/material/'
import { LogoutSharp } from '@mui/icons-material'
import useHttp from '../hooks/useHttp'
import useLogout from '../hooks/useLogout'
import MobileTopNav from '../components/mobile/MobileTopNav'
import MobileBottomNav from '../components/mobile/MobileBottomNav'
import DesktopNav from '../components/desktop/DesktopNav'
import FullPageProgress from '../components/FullPageProgress'

// eslint-disable-next-line no-restricted-globals
// const url = new URL('/api/user', location)

export default function Help ({ position, isMobile }) {
  const location = useLocation()
  const logout = useLogout()
  const token = location.state?.token || null
  const init = location.state?.init || {}
  const { user } = init
  const theme = useTheme()
  const drawerWidth = theme.mixins.drawerWidth
  // const { isLoading, error, sendRequest, reset } = useHttp()

  return (
    <Box component="main">
      <CssBaseline />
      {isMobile
        ? <>
          <MobileTopNav token={token} user={user} />
          <Toolbar />
        </>
        : <DesktopNav position={position} subPanelElements={[]} />
      }
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        width='100%'
        pl={!isMobile && `${drawerWidth}px`}
        // sx={theme => !isMobile && { [theme.breakpoints.down('lg')]: { ml: `${drawerWidth}px` } }}
      >
        <Typography
          sx={isMobile ? { mt: 2, mb: 1 } : { mt: 4, mb: 4 }}
          variant={isMobile ? 'h4' : 'h3'}
          align='center'
        >
          <Trans>Help</Trans>
        </Typography>
        <Divider flexItem variant='middle' />
        <Box
          width={isMobile ? '90%' : '80%'}
          maxWidth={isMobile ? 'xs' : 'sm'}
         >
              <img src='../assets/coming_soon_adams.png' alt='ADaMS coming soon' style={{ width: '75%' }} />
        </Box>
        {/* {error && <Alert sx={{ mt: 2 }} severity="error">
          <Trans>There was an error: {error}.</Trans>
        </Alert>}
        {isMobile && <MobileBottomNav />}
        <FullPageProgress isOpen={isLoading} isMobile={isMobile} /> */}
      </Box>
    </Box>
  )
}
