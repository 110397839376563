import { t } from '@lingui/macro'

export const randStr = (length = 4) =>
  Math.random().toString(36).substring(2, length + 2)

export const toCamelCase = str =>
  str
    .replace(/^\w|\b\w/g, (letter, index) =>
      index === 0 ? letter.toLowerCase() : letter.toUpperCase()
    )
    .replace(/\s+/g, '')

export const fromCamelCase = str =>
  str
    .replace(/([A-Z])/g, ' $1')
    .replace(/^\w|\b\w/g, (letter, index) =>
      index === 0 ? letter.toUpperCase() : letter.toLowerCase()
    )

export const isStringEqual = standard => value => {
  return standard === value
}

/**
 * Combina due o più funzioni usando logica OR.
 * @param  {...Array<(input: T) => boolean>} funcs - Le funzioni da combinare.
 * @returns {(input: T) => boolean} - Una funzione composta che restituisce true se una delle funzioni è true.
 */
export function or (...funcs) {
  return input => funcs.some(func => func(input))
}

/* Combines and completes online organizations info with local info (ie. localized descripition and icon) */
export const interpolateOrgs = orgs => [
  { id: -1, basic_id: -1, value: -1, description: t`Admin`, label: t`Admin`, icon: 'security' }
  , ...orgs.map(org => ({ ...org, value: org.id, label: t`${org.description}` }))
]

/* IndexedDB Utility to store failed requests when offline. */
const DB_NAME = 'crashSyncDB'
const STORE_NAME = 'pendingRequests'

export function openDB () {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, 1)
    request.onerror = () => reject(new Error('Failed to open IndexedDB'))
    request.onsuccess = () => resolve(request.result)
    request.onupgradeneeded = event => {
      event.target.result.createObjectStore(STORE_NAME, { keyPath: 'id', autoIncrement: true })
    }
  })
}

export async function saveToIndexedDB (data) {
  const db = await openDB()
  const tx = db.transaction(STORE_NAME, 'readwrite')
  tx.objectStore(STORE_NAME).add(data)
  return tx.complete
}

export async function getPendingRequests () {
  const db = await openDB()
  const tx = db.transaction(STORE_NAME, 'readonly')
  return tx.objectStore(STORE_NAME).getAll()
}

export async function clearIndexedDB () {
  const db = await openDB()
  const tx = db.transaction(STORE_NAME, 'readwrite')
  tx.objectStore(STORE_NAME).clear()
  return tx.complete
}
