import { useEffect, useState } from 'react'
import { Box, FormControl, TextField } from '@mui/material'
import { useFormContext, Controller } from 'react-hook-form'
import FilterFormElement from './FilterFormElement'

export default function StatsFilterForm ({
  title
  , isMobile
  , submitRef
  , form
  , formModel
  , formList
  , formArray
  , handleFilterObjSubmit
}) {
  const { handleSubmit, control, setValue } = useFormContext()
  const [secondColStart] = useState(Math.ceil(formModel[form.type].elements.length / 2))
  console.log(formArray, form.type, formModel)

  useEffect(() => {
    setValue('SubSection', title)
  }, [setValue, title])

  return (
    <Box
      component="form"
      ref={submitRef}
      onSubmit={handleSubmit(handleFilterObjSubmit)}
      display='flex'
      gap={2}
      pr={2}
      noValidate
    >
      <Box
        display='flex'
        flexDirection='column'
        flex={1}
        minWidth='50%'
      >
      <FormControl sx={{ display: 'none' }}>
        <Controller
          name='SubSection'
          control={control}
          render={({ field }) => (
            <TextField
              defaultValue={field.value}
              {...field}
            />
          )}
        />
      </FormControl>
      {formModel[form.type].elements
        .slice(0, secondColStart)
        .map(elem => (<FilterFormElement
          key={`${form.type}-${elem.label}`}
          elem={elem}
          isMobile={isMobile}
          form={form}
          formList={formList}
        />))}
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        flex={1}
        minWidth='50%'
      >
      {formModel[form.type].elements
        .slice(secondColStart)
        .map(elem => (<FilterFormElement
          key={`${form.type}-${elem.label}`}
          elem={elem}
          isMobile={isMobile}
          form={form}
          formList={formList}
        />))}
      </Box>
    </Box>
  )
}
