import React from 'react'

export default class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      hasError   : false
      , error    : null
    }
  }

  static getDerivedStateFromError (error) {
    // Update state so the next render will show the fallback UI.
    console.error('Error from', this.props.children, ':', error)
    return { hasError: true, error }
  }

  render () {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      // return this.props.fallback
      return (
        <div>
          <alert style={{ backgroundColor: 'red', position: 'absolute', top: '50%', width: '100%' }}>{this.state.error}</alert>
        </div>
      )
    }

    return this.props.children
  }
}
