import { Routes, Route } from 'react-router-dom'
import {
  createTheme,
  CssBaseline,
  ThemeProvider,
  useMediaQuery
} from '@mui/material'
import { RoutesProvider } from './store/routesContext'
import { DataMergingAndValidation } from './pages/DataMerginAndValidation'
import { MergeContext } from './store/mergeContext'
import Login from './pages/Login'
import Home from './pages/Home'
import Signup from './pages/Signup'
import User from './pages/User'
import Admin from './pages/Admin'
import Statistics from './pages/Statistics'
import History from './pages/History'
import Help from './pages/Help'
import { cloneElement, createContext, useMemo, useState } from 'react'

export const ThemeContext = createContext()

export default function App () {
  const [mode, setMode] = useState('light')
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode
          , primary: {
            main: mode === 'light' ? '#10171f' : '#f6d552'
          }
          , background: {
            default : mode === 'light' ? '#fff' : '#10171f'
            , paper   : mode === 'light' ? '#fff' : '#10171f'
          }
        }
        , typography: {
          root: {
            color: '#ccc'
          }
          , h3: {
            fontSize       : '1.5rem'
            , fontWeight   : 'bold'
          }
          , h5: {
            fontSize: '1.2rem'
          }
          , h4: {
            fontSize: '1.4rem'
          }
        }
        , mixins: {
          drawerWidth     : 256
          , formDrawerWidth : 320
          , toolbar         : {
            minHeight: 64
          }
        }
        , components: {
          MuiCardHeader: {
            styleOverrides: {
              root: {
                padding: '0 0 0 10px'
              }
            }
          }
          , MuiPaper: {
            styleOverrides: {
              gap: '12px'
            }
          }
          , MuiDataGrid: {
            styleOverrides: {
              root: {
                color             : 'black'
                , backgroundColor : '#ccc'
              }
              , columnHeaderTitle: {
                fontWeight      : 'bold'
                , textTransform : 'capitalize'
              }
              , toolbarContainer: {
                backgroundColor: '#aaa'
              }
            }
          }
          , MuiCollapse: {
            styleOverrides: {
              root: ({ theme }) => ({
                marginRight       : '0px'
                , marginLeft      : '10px'
                , boxShadow       : theme.palette.mode === 'dark' ? '-2px 0px 1px #f6d552' : '-2px 0px 1px rgba(0, 0, 0, 0.6)'
                , borderRadius    : '2px'
              })
            }
          }
        }
      }),
    [mode]
  )

  const isTouch = navigator.maxTouchPoints > 0

  const isMobileAgent = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

  if (isMobileAgent) {
    console.log('User is on a mobile device.')
  // Add mobile-specific logic here
  } else {
    console.log('User is on a desktop or tablet.')
  // Add desktop-specific logic here
  }

  const isChrome = /Chrome/i.test(navigator.userAgent)
  const isSafari = /Safari/i.test(navigator.userAgent) && !/Chrome/i.test(navigator.userAgent)
  const isFirefox = /Firefox/i.test(navigator.userAgent)

  if (isChrome) {
    console.log('User is on Chrome.')
  } else if (isSafari) {
    console.log('User is on Safari.')
  } else if (isFirefox) {
    console.log('User is on Firefox.')
  } else {
    console.log('User is on another browser.')
  }

  if (isMobileAgent) {
    if (isChrome) {
      console.log('User is on a mobile device using Chrome.')
    // Add mobile Chrome-specific logic here
    } else if (isSafari) {
      console.log('User is on a mobile device using Safari.')
    // Add mobile Safari-specific logic here
    } else {
      console.log('User is on a mobile device using another browser.')
    // Add generic mobile browser logic here
    }
  } else {
    console.log('User is on a desktop or tablet.')
  // Add desktop-specific logic here
  }

  const platform = navigator.platform

  if (/Win/i.test(platform)) {
    console.log('User is on Windows.')
  } else if (/Mac/i.test(platform)) {
    console.log('User is on macOS.')
  } else if (/Linux/i.test(platform)) {
    console.log('User is on Linux.')
  } else if (/iPhone|iPad|iPod/i.test(platform)) {
    console.log('User is on iOS.')
  } else if (/Android/i.test(platform)) {
    console.log('User is on Android.')
  } else {
    console.log('User is on an unknown platform.')
  }

  const isSmallScreen = window.matchMedia('(max-width: 600px)').matches

  if (isSmallScreen) {
    console.log('User is on a small screen (likely a mobile device).')
  } else {
    console.log('User is on a larger screen (likely a desktop or tablet).')
  }

  const isStandalone = window.matchMedia('(display-mode: standalone)').matches

  if (isStandalone) {
    console.log('PWA is running in standalone mode.')
  } else {
    console.log('PWA is running in a browser tab.')
  }

  const isMobile = isStandalone || isTouch
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const toggleTheme = mode => {
    if (mode) setMode(mode)
    else setMode(prevMode => (prevMode === 'light' ? 'dark' : 'light'))
  }

  const routes = [
    { path: '/home', element: <Home /> }
    , { path: '/user', element: <User /> }
    , { path: '/statistics', element: <Statistics /> }
    , { path: '/admin', element: <Admin /> }
    , {
      path    : '/tools'
      , element : (
        <MergeContext>
          <DataMergingAndValidation />
        </MergeContext>
      )
    }
    , { path: '/history', element: <History /> }
    , { path: '/help', element: <Help /> }
  ]

  const [handledCrash, setHandledCrash] = useState({})
  const handleRowClick = ({ row }) => setHandledCrash({
    id             : row['Crash id']
    , organization : row.organization
    , reporterId   : row.merge_date ? undefined : row.user_id
  })

  return (
    <ThemeContext.Provider value={{ mode, toggleTheme }}>
      <ThemeProvider theme={theme}>

        <CssBaseline />
        <RoutesProvider routes={routes.map(route => route.path)}>
          <Routes>
            <Route path="/" element={<Login isMobile={isMobile} />} />
            <Route path="/signup" element={<Signup isMobile={isMobile} />} />
            {routes.map(({ path, element }, position) =>
              <Route
                key={path}
                path={path}
                element={cloneElement(element, { position, isMobile, handleRowClick, handledCrash })}
              />)}
          </Routes>
        </RoutesProvider>
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}

// const defaultTheme = createTheme({})
// , components: {
//   MuiCssBaseline: {
//     styleOverrides: themeParams => ({
//       body: {
//         scrollbarColor                                   : '#ebebeb #2b2b2b'
//         , '&::-webkit-scrollbar, & *::-webkit-scrollbar' : {
//           backgroundColor: '#ebebeb'
//         }
//         , '&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track': {
//           width           : '8px'
//           , backgroundColor : orange[500]// '#6b6b6b'
//         }
//         , '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
//           borderRadius      : 8
//           , backgroundColor : orange[500]// '#6b6b6b'
//           , minHeight       : 24
//           , border          : '0px solid #2b2b2b'
//         }
//         , '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
//           backgroundColor: '#959595'
//         }
//         , '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
//           backgroundColor: '#959595'
//         }
//         , '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
//           backgroundColor: '#959595'
//         }
//         , '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
//           backgroundColor: '#ff0000'
//         }
//       }
//     })
//   }
// }

// {
//   '& .scroll-bar::-webkit-scrollbar-track'   : { width: '8px' }
//   , '& .scroll-bar::-webkit-scrollbar-track' : { background: '#d5d2d2' }
//   , '& .scroll-bar::-webkit-scrollbar-thumb' : { backgroundColor: '#7e7c7e',  borderRadius: '4px', border: '0px solid #7e7c7e' }
//   ,'& .scroll-bar::-webkit-scrollbar-thumb:hover': { backgroundColor: '#494849' }
// }
