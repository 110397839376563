import { useState } from 'react'
import {
  Box
  , Drawer as MuiDrawer
  , FormControlLabel
  // , IconButton
  , List
  , ListItem
  , ListItemButton
  , ListItemIcon
  , ListItemText
  , styled
  , Switch
  , useTheme
} from '@mui/material'

export default function MobileLayersPanel ({
  open
  , subPanelElements
}) {
  const theme = useTheme()
  const toolbarHeight = theme.mixins.toolbar.minHeight
  const [switches, setSwitches] = useState(() =>
    subPanelElements.filter(e => e.onMobile).map(() => false)
  )

  const Drawer = styled(MuiDrawer)({
    width                : '100vw'
    , '& .MuiDrawer-paper' : {
      width          : '100vw'
      , height       : `calc(${toolbarHeight}px + 2rem * ${switches.length})`
      , marginBottom : `${toolbarHeight}px`
    }
  })

  const composeList = (elem, index) => {
    if (elem.type === 'switch') {
      return (
        <ListItem key={elem.label}>
          <FormControlLabel
            checked={switches[index]}
            control={<Switch />}
            label={elem.label}
            onChange={e => {
              // setSwitches({ ...switches, [e.target.name]: e.target.checked })
              setSwitches(old => old.map((o, i) => i === index ? e.target.checked : o))
              elem.action(e.target.checked)
            }}
          />
        </ListItem>
      )
    }
    if (elem.type === 'button') {
      return (
        <ListItem disablePadding key={elem.label}>
          <ListItemButton onClick={elem.action}>
            <ListItemIcon>
              {elem.icon}
            </ListItemIcon>
            <ListItemText primary={elem.label} />
          </ListItemButton>
        </ListItem>
      )
    }
    return ''
  }

  return (
    <Drawer
      variant="persistent"
      open={open}
      anchor='bottom'
    >
      <Box display='flex' justifyContent='space-evenly' flexWrap='wrap' overflow='auto'>
        <List dense disablePadding>
          {subPanelElements
            .filter(elem => elem.onMobile)
            // .filter((_, index) => index % 2)
            .map(composeList)
          }
        </List>
        {/* <List dense disablePadding>
          {subPanelElements
            .filter(elem => elem.onMobile)
            .filter((_, index) => !index % 2)
            .map(composeList)
          }
        </List> */}
      </Box>
    </Drawer>
  )
}
